/* eslint-disable no-use-before-define */
/* eslint-disable max-classes-per-file */
/* eslint-disable max-lines */
import { modelOptions, prop, Ref, Severity } from '@typegoose/typegoose';

import {
  DeclarationCustomsFlowType,
  DeclarationCustomsType,
  DeclarationDocumentsAdditionalFiscalReferenceRole,
  DeclarationLogType,
  DeclarationStatus,
} from '../enums';
import { BaseModel } from './base.model';
import { Batch } from './batch';
import { Customer } from './customer';
import { Declarant, DeclarantAddress, DeclarantContact } from './declarant';
import { DeclarationGoods, TaxBase } from './declaration.goods';
import { DomainCollection } from './domain.collection';
import { CustomsOffice, EconomicOperator } from './goodLocation';
import { User } from './user';
import { ValuationInformationTaxes } from './valuation-information-taxes';

export class DeclarationGeneralInfo {
  @prop({ required: true, enum: DeclarationStatus })
  status: string;

  @prop()
  group: string;

  /**
   * only for high values declarations
   */
  @prop()
  isExport?: boolean;

  @prop({ default: false })
  complianceCheck: boolean;

  @prop({ default: false })
  webScrapingDone: boolean;
}

export class DeclarationMessageInfo {
  @prop()
  security?: number;

  @prop()
  transport?: string;

  @prop({ required: true, ref: () => Batch })
  batch: Ref<Batch>;

  @prop({ required: true })
  batchId: number;

  @prop()
  LRN?: string;

  @prop()
  declarationType?: string;

  @prop()
  declarationMainType?: string;

  @prop()
  deferredPayment?: string;

  @prop()
  deferredPaymentVAT?: string;

  @prop()
  methodOfPayment?: string;

  @prop()
  methodOfPaymentVAT?: string;

  @prop()
  acceptanceDate?: string;
}

export class GoodShipmentGlobalFirstItemData {
  @prop()
  hsCode: string;

  @prop()
  invoiceDescription: string;

  @prop({ required: false })
  nationalAdditionalCode?: string;

  @prop({ required: false })
  taricAdditionalCode?: string;

  @prop()
  typeOfGoods?: string;

  @prop()
  cusCode?: string;
}

export class GoodShipmentGlobal {
  @prop()
  vatSellerOk: boolean;

  @prop()
  totalQuantity: number;

  @prop()
  totalQuantitySupplementaryUnits?: number;

  @prop()
  totalAmountInvoiced: number;

  @prop()
  totalGrossMass: number;

  @prop()
  totalNetMass: number;

  @prop()
  totalNumberOfPackages: number;

  @prop()
  totalDutiesAndTaxesAmount: number;

  @prop({ default: 0 })
  webTotalDutiesAndTaxes: number;

  @prop({ default: 0 })
  minimumAmountToPayForDutiesAndTaxes: number;

  @prop({ default: 0 })
  initialAmountToPayForDutiesAndTaxes: number;

  @prop({ default: 0 })
  minimumAmountToPayForDuties: number;

  @prop({ default: 0 })
  initialAmountToPayForDuties: number;

  @prop({ default: 0 })
  minimumAmountToPayForVat: number;

  @prop({ default: 0 })
  initialAmountToPayForVat: number;

  @prop({ default: 0 })
  minimumAmountToPayForDutiesTaxesAndPenalties: number;

  @prop()
  totalPenalties: number;

  @prop()
  impactInEuro: number;

  @prop()
  webMatchingDescription: boolean;

  @prop()
  invoicedRateWebOK: boolean;

  @prop()
  hsDescriptionOK: boolean;

  @prop()
  anomaliesDetected: boolean;

  // only for low values declarations
  @prop()
  splitShipment: boolean;

  @prop()
  invoiceCurrency: string;

  @prop()
  internalCurrencyUnit: string;

  @prop()
  exchangeRate: number;

  @prop({ _id: false, type: GoodShipmentGlobalFirstItemData })
  firstItemData: GoodShipmentGlobalFirstItemData;

  @prop()
  customsProcedure: string;

  @prop()
  customsNationalProcedure: string;

  @prop()
  preference: string;

  @prop()
  customsProcedureType: string;

  @prop()
  customsNationalProcedureCode: string;

  @prop()
  sequence: number;

  @prop()
  intrinsicValue?: number;

  @prop()
  intrinsicValueLow?: number;

  @prop()
  undervaluation?: boolean;

  @prop()
  wrongIOSS?: boolean;

  /**
   * the value taken from the first goodsShipmentItem.
   * Used in finalize batch
   */
  hsCodeFound?: boolean;
}

export class StakeholderAddress {
  @prop()
  street: string;

  @prop()
  postcode: string;

  @prop()
  city: string;

  @prop()
  country: string;
}

export class EvalEORI {
  @prop()
  eoriOk: boolean;

  @prop()
  result: string;
}

export class StakeholderImporter {
  @prop()
  name: string;

  @prop()
  identificationNumber: string;

  @prop({ _id: false, type: EvalEORI })
  evalEORI?: EvalEORI;

  @prop({ _id: false, type: StakeholderAddress })
  address: StakeholderAddress;
}

export class StakeholderExporter {
  @prop({ default: true })
  header?: boolean;

  @prop()
  name: string;

  @prop({ _id: false, type: StakeholderAddress })
  address: StakeholderAddress;

  @prop()
  identificationNumber: string;
}

export class EvalVAT {
  @prop()
  vatOk: boolean;

  @prop()
  result: string;
}

export class StakeholderIntracom {
  @prop()
  name: string;

  @prop()
  identificationNumber: string;

  @prop({ _id: false, type: EvalVAT })
  evalVAT?: EvalVAT;

  @prop({ _id: false, type: StakeholderAddress })
  address: StakeholderAddress;
}

export class StakeholderDeclarantContact {
  @prop()
  name: string;

  @prop()
  phoneNumber: string;

  @prop()
  email: string;
}

export class StakeholderDeclarantAddress {
  @prop()
  street: string;

  @prop()
  postcode: string;

  @prop()
  city: string;

  @prop()
  country: string;
}

export class StakeholderDeclarant {
  @prop()
  name: string;

  @prop()
  identificationNumber: string;

  @prop({ _id: false, type: StakeholderDeclarantAddress })
  address: DeclarantAddress;

  @prop({ _id: false, type: StakeholderDeclarantContact })
  contactPerson?: StakeholderDeclarantContact;
}

export class StakeholderDeclarantRepresentative {
  @prop()
  name: string;

  @prop()
  phoneNumber: string;

  @prop()
  email: string;
}

export class StakeholderPersonProvidingGuarantee {
  @prop()
  identificationNumber: string;
}

export class StakeholderPersonPayingTheCustomDuty {
  @prop()
  identificationNumber: string;
}

export class AdditionalSupplyChainActor extends StakeholderImporter {
  @prop({ default: true })
  header: boolean;

  @prop()
  role: string;
}

export class Stakeholders {
  @prop({ _id: false, type: StakeholderImporter })
  importer: StakeholderImporter;

  @prop({ _id: false, type: StakeholderExporter })
  exporter: StakeholderExporter;

  @prop({ _id: false, type: StakeholderIntracom })
  intracom: StakeholderIntracom;

  @prop({ _id: false, type: StakeholderDeclarantRepresentative })
  declarantRepresentative: StakeholderDeclarantRepresentative;

  @prop({ _id: false, type: StakeholderDeclarant })
  declarant: StakeholderDeclarant;

  @prop({ _id: false, type: StakeholderPersonProvidingGuarantee })
  personProvidingGuarantee: StakeholderPersonProvidingGuarantee;

  @prop({ _id: false, type: StakeholderPersonPayingTheCustomDuty })
  personPayingTheCustomDuty: StakeholderPersonPayingTheCustomDuty;

  @prop({ _id: false, type: AdditionalSupplyChainActor })
  additionalSupplyChainActor: AdditionalSupplyChainActor;
}

export class DV1Contact {
  @prop()
  contractNumber: string;

  @prop()
  contractDate: string;
}

export class DV1BuyerSellerRelation {
  @prop()
  relation: string;
}

export class DV1RestrictionsAndConditions {
  @prop()
  restrictions: string;

  @prop()
  consideration: string;

  @prop()
  resale: string;
}

export class DV1RoyaltiesAndResale {
  @prop()
  royalties: string;
}

export class DV1Signature {
  @prop()
  place: string;
}

export class DV1Buyer extends StakeholderExporter {}

export class DV1Seller extends StakeholderExporter {}

export class DV1 {
  @prop({ _id: false, type: DV1Buyer })
  buyer: DV1Buyer;

  @prop({ _id: false, type: DV1Seller })
  seller: DV1Seller;

  @prop({ _id: false, type: DV1Contact })
  contract: DV1Contact;

  @prop({ _id: false, type: DV1BuyerSellerRelation })
  BuyerSellerRelation: DV1BuyerSellerRelation;

  @prop({ _id: false, type: DV1RestrictionsAndConditions })
  RestrictionsAndConditions: DV1RestrictionsAndConditions;

  @prop({ _id: false, type: DV1RoyaltiesAndResale })
  RoyaltiesAndResale: DV1RoyaltiesAndResale;

  @prop({ _id: false, type: DV1Signature })
  signature: DV1Signature;
}

export class Document {
  @prop({ default: true })
  header?: boolean;

  @prop()
  referenceNumber: string;

  @prop()
  type: string;

  @prop()
  dateOfValidity: string;
}

export class SupportingDocument extends Document {
  @prop()
  documentLineItemNumber?: number;

  @prop()
  issuingAuthorityName?: string;

  @prop()
  measurementUnitAndQualifier?: string;

  @prop()
  quantity?: number;

  @prop()
  currency?: string;

  @prop()
  amount?: number;
}

export class AdditionalReference extends Document {
  @prop()
  producedDocumentsValidationOffice?: string;
}

export class PreviousDocument extends Document {
  @prop()
  category: string;

  @prop()
  location: string;

  @prop()
  article?: string;

  @prop()
  billOfLoading?: string;

  @prop()
  item?: string;

  @prop()
  typeOfPackages?: string;

  @prop()
  numberOfPackages?: number;

  @prop()
  measurementUnitAndQualifier?: string;

  @prop()
  quantity?: number;

  @prop()
  goodsItemIdentifier?: string;
}

export class GuaranteeDocumentReferenceCustomsOffice {
  @prop()
  referenceNumber: string;
}

export class GuaranteeDocumentReference {
  @prop()
  grn: string;

  @prop()
  dateOfValidity: string;

  @prop()
  accessCode: string;

  @prop()
  currencyCode: string;

  @prop()
  amountTobeCovered: number;

  @prop()
  otherGuaranteeReference: string;

  @prop({ type: GuaranteeDocumentReferenceCustomsOffice, _id: false })
  customsOfficeGuarantee: GuaranteeDocumentReferenceCustomsOffice;
}
export class GuaranteeDocument {
  @prop({ default: true })
  header?: boolean;

  @prop()
  type: string;

  @prop({ type: GuaranteeDocumentReference, _id: false })
  reference?: GuaranteeDocumentReference;
}

export class AdditionalInformation {
  @prop({ default: true })
  header?: boolean;

  @prop()
  code: string;

  @prop()
  text: string;

  @prop()
  dateOfValidity: string;
}

export class AdditionalFiscalReference {
  @prop({ default: true })
  header?: boolean;

  @prop({ enum: DeclarationDocumentsAdditionalFiscalReferenceRole })
  role: string;

  @prop()
  vatIdentificationNumber: string;

  @prop()
  dateOfValidity: string;
}

export class TransportDocument {
  @prop({ default: true })
  header?: boolean;

  @prop()
  referenceNumber: string;

  @prop()
  type: string;

  @prop()
  dateOfValidity: string;
}

export class AuthorisationDocument {
  @prop({ default: true })
  header?: boolean;

  @prop()
  type: string;

  @prop()
  referenceNumber: string;

  @prop()
  holderOfTheAuthorisation: string;
}

export class AdditionsAndDeductionsDocument {
  @prop({ default: true })
  header?: boolean;

  @prop()
  code: string;

  @prop()
  amount: number;
}

export class WarehouseDocuments {
  @prop({ default: true })
  header?: boolean;

  @prop()
  type: string;

  @prop()
  identifier: string;
}

export class Documents {
  @prop({ _id: false, type: [PreviousDocument] })
  previousDocuments: PreviousDocument[];

  @prop({ _id: false, type: [SupportingDocument] })
  supportingDocuments: SupportingDocument[];

  @prop({ _id: false, type: [AdditionalReference] })
  additionalReference: AdditionalReference[];

  @prop({ _id: false, type: [GuaranteeDocument] })
  guaranteeDocuments: GuaranteeDocument[];

  @prop({ _id: false, type: [AdditionalInformation] })
  additionalInformation: AdditionalInformation[];

  @prop({ _id: false, type: [AdditionalFiscalReference] })
  additionalFiscalReference: AdditionalFiscalReference[];

  @prop({ _id: false, type: [TransportDocument] })
  transportDocuments: TransportDocument[];

  @prop({ _id: false, type: [AuthorisationDocument] })
  authorisationDocuments: AuthorisationDocument[];

  @prop({ _id: false, type: [AdditionsAndDeductionsDocument] })
  additionsAndDeductionsDocuments: AdditionsAndDeductionsDocument[];

  @prop({ _id: false, type: [WarehouseDocuments] })
  warehouseDocuments: WarehouseDocuments[];
}

export class LocationOfGoodsAddress {
  @prop()
  streetAndNumber: string;

  @prop()
  postcode: string;

  @prop()
  city: string;

  @prop()
  country: string;
}

export class LocationOfGoodsPostcode {
  @prop()
  postcode: string;

  @prop()
  houseNumber: string;

  @prop()
  country: string;
}

export class LocationOfGoods {
  @prop()
  unLocode: string;

  @prop()
  address?: LocationOfGoodsAddress;

  @prop()
  postcode?: LocationOfGoodsPostcode;

  @prop()
  typeOfLocation?: string;

  @prop()
  qualifierOfIdentification?: string;

  @prop()
  authorisationNumber?: string;

  @prop()
  additionalIdentifier?: string;

  @prop()
  economicOperator?: EconomicOperator;

  @prop()
  customsOffice?: CustomsOffice;
}

export class TransportCost {
  @prop()
  amount: number;

  @prop()
  currencyCode: string;
}

export class IntrinsicValue {
  @prop({ required: true })
  amount: number;

  @prop({ required: true })
  currencyCode: string;
}

export class CustomsOfficeExport {
  @prop()
  referenceNumber: string;
}

export class CustomsOfficeExit {
  @prop()
  referenceNumber: string;
}

export class CountryOfRoutingOfConsignment {
  @prop()
  country: string;
}
export class CustomsOfficePresentation {
  @prop()
  referenceNumber?: string;

  @prop()
  issuingPlaceCode?: string;
}

export class CustomsSupervisingOffice {
  @prop()
  referenceNumber: string;
}

export class Consignment {
  @prop()
  referenceNumberUCR?: string;

  @prop({ default: true })
  referenceNumberUCRHeader?: boolean;

  @prop()
  grossMass?: number;

  @prop()
  countryOfOrigin?: string;

  @prop()
  regionOfOrigin?: string;

  @prop()
  countryOfPreferentialOrigin?: string;

  @prop()
  countryOfExportHeader?: boolean;

  @prop()
  countryOfExport?: string;

  // header for countryOfDestination and regionOfDestination
  @prop()
  destinationHeader?: boolean;

  @prop()
  countryOfDestination?: string;

  @prop()
  regionOfDestination?: string;

  @prop({ _id: false, type: LocationOfGoods })
  locationOfGoods?: LocationOfGoods;

  @prop({ _id: false, type: TransportCost })
  transportCost?: TransportCost;

  @prop({ _id: false, type: IntrinsicValue })
  intrinsicValue?: IntrinsicValue;

  @prop({ _id: false, type: CustomsOfficePresentation })
  customsOfficePresentation?: CustomsOfficePresentation;

  @prop({ _id: false, type: CustomsSupervisingOffice })
  customsSupervisingOffice?: CustomsSupervisingOffice;

  @prop()
  countryOfDispatch?: string;

  @prop({ default: true })
  countryOfDispatchHeader?: boolean;

  @prop({ _id: false, type: CustomsOfficeExport })
  customsOfficeExport?: CustomsOfficeExport;

  @prop({ _id: false, type: CustomsOfficeExit })
  customsOfficeExit?: CustomsOfficeExit;
}

export class ArrivalTransportMeans {
  @prop()
  typeOfIdentification?: number;

  @prop()
  identificationNumber: string;
}

export class TransportEquipmentContainer {
  @prop()
  identificationNumber: string;
}

export class TransportEquipment {
  @prop({ default: true })
  header?: boolean;

  /**
   * @deprecated use containers
   */
  @prop()
  containerIdentification?: string;

  @prop({ _id: false, type: [TransportEquipmentContainer] })
  containers?: TransportEquipmentContainer[];

  @prop({ _id: false, type: [TransportEquipmentContainer] })
  seals?: TransportEquipmentContainer[];
}

export class ActiveBorderTransportMeans {
  @prop()
  typeOfIdentification?: string;

  @prop()
  identificationNumber: string;

  @prop()
  nationality: string;
}

export class DepartureTransportMeans {
  @prop()
  typeOfIdentification?: string;

  @prop()
  identificationNumber: string;

  @prop()
  nationality?: string;
}

export class TransportInformation {
  @prop()
  inlandModeOfTransport: string;

  @prop()
  modeOfTransportMeansAtTheBorder: string;

  @prop({ _id: false })
  arrivalTransportMeans: ArrivalTransportMeans;

  @prop({ _id: false })
  transportEquipment: TransportEquipment;

  @prop({ _id: false, type: ActiveBorderTransportMeans })
  activeBorderTransportMeans: ActiveBorderTransportMeans;

  @prop({ _id: false, type: DepartureTransportMeans })
  departureTransportMeans: DepartureTransportMeans;

  @prop({ _id: false, type: [CountryOfRoutingOfConsignment] })
  countryOfRoutingOfConsignment?: CountryOfRoutingOfConsignment[];
}

export class ReleaseForImport {
  @prop()
  dateOfRelease: string;
}

export class NoRelease {
  @prop()
  rejectionDate: string;

  @prop()
  rejectionMotivationText: string;
}

export class InControl {
  @prop()
  notificationDate: string;

  @prop()
  controlType: string;
}

export class ControlResult {
  @prop()
  code: string;

  @prop()
  date: string;

  @prop()
  remarks: string;
}

export class CustomsDeclarationRejection {
  @prop()
  businessRejectionType: string;

  @prop()
  businessRejectionDescription: string;

  @prop()
  rejectionDate: string;

  @prop()
  rejectionCode: string;

  @prop()
  rejectionReason: string;
}

@modelOptions({ options: { allowMixed: Severity.ALLOW } })
export class FunctionalError {
  @prop()
  sequenceNumber?: number;

  @prop()
  errorPointer: string;

  @prop()
  errorCode: number | string;

  @prop()
  errorReason: string;

  @prop()
  remarks?: string;

  @prop()
  type?: string;
}

export class InvalidationRequest {
  @prop()
  invalidationReason: string;

  @prop()
  invalidationRequestDate: string;
}
export class InvalidationDecision {
  @prop()
  invalidationDecisionDate: string;

  @prop()
  invalidationRequestDate: string;

  @prop()
  invalidationInitiatedByCustoms: number;

  @prop()
  invalidationReason: string;
}

export class CustomLog {
  @prop()
  date: string;

  @prop({ enum: DeclarationLogType })
  type: string;

  @prop()
  customsMessageCode: string;

  @prop()
  log: string;
}

export class CalculationResult {
  @prop()
  taxType: string;

  @prop()
  payableTaxAmount: number;

  @prop()
  paymentMethod: string;

  @prop({ _id: false, type: TaxBase })
  taxBase: TaxBase;
}

export class CalculationResultTotal {
  @prop()
  taxType: string;

  @prop()
  payableTaxAmount: number;

  @prop()
  paymentMethod: string;
}

export class CalculationResults {
  @prop({ _id: false, type: [CalculationResult] })
  items: CalculationResult[];

  @prop({ _id: false, type: [CalculationResultTotal] })
  total: CalculationResultTotal[];
}

export class DeclarationGroup {
  @prop()
  batchName: string;

  @prop()
  customerName: string;

  @prop()
  totalPackages: number;

  @prop()
  totalQuantity: number;

  @prop()
  grossMass: number;

  @prop()
  netMass: number;

  @prop()
  numberOfArticles: number;
}

export class CustomsStateBegate {
  @prop()
  beGateStatus: string;

  @prop({ type: Date })
  beGateSentDate: Date;

  @prop()
  beGateClearanceNumber: string;

  @prop()
  beGateClearanceDate: string;
}

export class CustomsStatePrelodge {
  @prop()
  correlationId?: string;

  @prop({ type: Date })
  acceptanceDate?: Date;

  @prop()
  mrn?: string;

  @prop({ required: false, enum: DeclarationStatus })
  status?: string;
}

export class CustomsState {
  @prop()
  corelationId: string;

  @prop()
  movementReference: string;

  @prop()
  dateOfAcceptance: string;

  @prop({ _id: false, type: ReleaseForImport })
  releaseForImport: ReleaseForImport;

  @prop({ _id: false, type: NoRelease })
  noRelease: NoRelease;

  @prop({ _id: false, type: InControl })
  inControl: InControl;

  @prop({ _id: false, type: [ControlResult] })
  controlResult: ControlResult[];

  @prop({ _id: false, type: CustomsDeclarationRejection })
  customsDeclarationRejection: CustomsDeclarationRejection;

  @prop({ _id: false, type: [FunctionalError] })
  functionalError: FunctionalError[];

  @prop({ _id: false, type: InvalidationRequest })
  invalidationRequest: InvalidationRequest;

  @prop({ _id: false, type: InvalidationDecision })
  invalidationDecision: InvalidationDecision;

  @prop({ _id: false, type: [CustomLog] })
  customsLogs: CustomLog[];

  @prop({ _id: false, type: CalculationResults })
  calculationResults: CalculationResults;

  @prop({ _id: false, type: CustomsStateBegate })
  begate: CustomsStateBegate;

  @prop({ required: false, enum: DeclarationCustomsType })
  sentTo?: string;

  @prop({ required: false, enum: DeclarationCustomsFlowType, type: String })
  flow?: DeclarationCustomsFlowType;

  @prop({ _id: false, type: CustomsStatePrelodge })
  prelodge?: CustomsStatePrelodge;
}

export class CustomsReportDeclarantRepresentative {
  @prop()
  identificationNumber: string;

  @prop()
  name: string;

  @prop()
  status: number;

  @prop({ _id: false, type: DeclarantAddress })
  address: DeclarantAddress;
}

export class CustomsReportDeclarant {
  @prop()
  identificationNumber: string;

  @prop()
  name: string;

  @prop({ _id: false, type: DeclarantAddress })
  address: DeclarantAddress;

  @prop({ _id: false, type: DeclarantContact })
  contactPerson: DeclarantContact;

  @prop({ _id: false, type: CustomsReportDeclarantRepresentative })
  representative: CustomsReportDeclarantRepresentative;
}

export class CustomsReport {
  @prop({ _id: false, type: CustomsReportDeclarant })
  declarant: CustomsReportDeclarant;

  @prop()
  totalAmountInvoiced: number;

  @prop()
  exchangeRate: number;

  @prop()
  currency: string;
}

export class TraceCreation {
  @prop()
  creationDate: string;

  @prop()
  userId: string;
}

export class DeclarationTraceDataChange {
  @prop()
  fieldName: string;

  @prop()
  oldFieldValue: string;

  @prop()
  newFieldValue: string;
}

export class Sending {
  @prop()
  sendingDate: string;

  @prop()
  userId: string;
}

export class DeclarationTrace {
  @prop({ ref: () => 'User' })
  userId: Ref<User>;

  @prop()
  userName: string;

  @prop()
  dateTime: string;

  @prop({ _id: false, type: [DeclarationTraceDataChange] })
  dataChanges: DeclarationTraceDataChange[];
}

export class DeclarationRiskAnalysisTrace {
  @prop({ ref: () => 'User' })
  userId: Ref<User>;

  @prop()
  dateTime: string;

  @prop({ default: 0 })
  validatedHSCodeCheck: number;

  @prop({ default: 0 })
  suggestedHSCode: number;

  @prop({ default: 0 })
  validatedDescriptionCheck: number;

  @prop({ default: 0 })
  suggestedDescription: number;
}

export class Trace {
  @prop({ _id: false, type: TraceCreation })
  creation: TraceCreation;

  @prop({ _id: false, type: [DeclarationTrace] })
  declarationTrace: DeclarationTrace[];

  @prop({ _id: false, type: DeclarationRiskAnalysisTrace })
  riskAnalysisTrace?: DeclarationRiskAnalysisTrace;

  @prop({ _id: false, type: [Sending] })
  sending: Sending[];
}

export class ContactPerson {
  @prop()
  email: string;
}

export class GroupedDeclaration {
  _id: string;

  declarations: [
    {
      declarationId: string;
      mrn?: string;
    },
  ];
}

export class OtherDataElements {
  @prop()
  statisticalValue: number;

  @prop()
  natureOfTransaction: string;

  @prop({ default: true })
  natureOfTransactionHeader?: boolean;

  @prop()
  registrationNumber: string;

  @prop()
  quotaOrderNumber: string;
}

export class GlobalInfo {
  totalAmountInvoiced: number;

  invoiceCurrency: string;

  totalPCS: number;

  totalCNTS: number;

  totalWeight: number;

  valuationInformationTaxes: ValuationInformationTaxes;

  goodShipmentGlobals: GoodShipmentGlobal[];
}

export class DeclarationCustomsProof {
  @prop()
  format: string;

  @prop()
  proof: string;

  // used in frontend
  decodedProof?: any;
}

export class DeclarationVariables {
  intraAmount: number;

  intraCurrency: string;

  extraAmount: number;

  extraCurrency: string;
}

@DomainCollection({ schemaOptions: { timestamps: true } })
export class Declaration extends BaseModel {
  @prop({ _id: false, type: DeclarationGeneralInfo })
  generalInfo: DeclarationGeneralInfo;

  @prop({ _id: false, type: DeclarationMessageInfo })
  messageInfo: DeclarationMessageInfo;

  // TODO - remove _id when possible
  @prop({ type: [DeclarationGoods] })
  goodsShipmentItems: DeclarationGoods[];

  @prop({ _id: false, type: GoodShipmentGlobal })
  goodShipmentGlobal: GoodShipmentGlobal;

  @prop({ _id: false, type: Stakeholders })
  stakeholders: Stakeholders;

  @prop({ _id: false, type: DV1 })
  dv1: DV1;

  @prop({ _id: false, type: Documents })
  documents: Documents;

  @prop({ _id: false, type: Consignment })
  consignment: Consignment;

  @prop({ _id: false, type: ValuationInformationTaxes })
  valuationInformationTaxes: ValuationInformationTaxes;

  @prop({ _id: false, type: TransportInformation })
  transportInformation: TransportInformation;

  // only for high values with sequence 1
  @prop({ _id: false, required: false, type: DeclarationGroup })
  declarationGlobal?: DeclarationGroup;

  @prop({ _id: false, type: CustomsState })
  customsState: CustomsState;

  @prop({ _id: false, type: CustomsReport })
  customsReport: CustomsReport;

  @prop({ _id: false, type: Trace })
  trace: Trace;

  @prop({ ref: () => Declarant })
  declarant: Ref<Declarant>;

  @prop({ default: false })
  deleted: boolean;

  @prop()
  savedDeclarantJSON: string;

  savedDeclarant: Declarant;

  @prop({ ref: () => User, required: true })
  createdBy: Ref<User>;

  @prop({ default: false })
  isArchived: boolean;

  @prop({ _id: false, type: ContactPerson })
  contactPerson?: ContactPerson;

  @prop({ _id: false, type: OtherDataElements })
  otherDataElements?: OtherDataElements;

  @prop({ ref: () => Customer })
  customer?: Ref<Customer>;

  @prop({ _id: false })
  customsProof: DeclarationCustomsProof;

  // not stored in db
  goodsShipmentItemsLength?: number;

  @prop({ default: false })
  groupByShippingMark: boolean;

  @prop()
  transactionType: number;

  @prop()
  decryptionDone?: boolean;

  @prop()
  nlSequence: string;

  // not stored in db; used in finalize batch
  globalInfo?: GlobalInfo;

  // used in atlas search pagination
  paginationToken?: string;

  // used when uploading a batch to store some variables used in the process
  variables?: DeclarationVariables;
}
