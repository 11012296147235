/* eslint-disable no-console */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import { Importer, POADocumentType } from '@e-origin/shared';

import { request } from '../utils';
import { AppThunk, RootState } from './';

interface IImportersState {
  importerDetails: Importer;
  list: Importer[];
  meta: { total: number };
  isLoading: boolean;
}

const initialState: IImportersState = {
  importerDetails: undefined,
  list: [],
  meta: { total: 0 },
  isLoading: false,
};

export const importersSlice = createSlice({
  name: 'importersSlice',
  initialState,
  reducers: {
    setImporterDetails: (state: IImportersState, action: PayloadAction<Importer>) => {
      state.importerDetails = action.payload;
    },
    setList: (state: IImportersState, action: PayloadAction<Importer[]>) => {
      state.list = [...action.payload];
    },
    setMeta: (state: IImportersState, action: PayloadAction<{ total: number }>) => {
      state.meta = action.payload;
    },
    setIsLoading: (state: IImportersState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    freeMemory: (state: IImportersState) => {
      state.list = [];
      state.meta = { total: 0 };
    },
  },
});

export const selectImporters = (state: RootState) => state.importersSlice.list;

export const selectImportersMeta = (state: RootState) => state.importersSlice.meta;

export const selectImportersIsLoading = (state: RootState) => state.importersSlice.isLoading;

export const selectImporterDetails = (state: RootState) => state.importersSlice.importerDetails;

export const importersFreeMemory = importersSlice.actions.freeMemory;

export const fetchImportersMetadata =
  (filters?: any): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      dispatch(importersSlice.actions.setMeta({ total: 0 }));
      const { metadata } = await request({
        path: `importer/retrieve-importers-metadata`,
        method: 'POST',
        authenticate: true,
        dataObject: { filters },
      });
      dispatch(importersSlice.actions.setMeta({ total: metadata.total }));
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const fetchImporters =
  (criteria?: any): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      dispatch(importersSlice.actions.setIsLoading(true));
      const res = await request({
        path: `importer/retrieve-importers`,
        method: 'POST',
        authenticate: true,
        dataObject: {
          criteria,
        },
      });
      dispatch(importersSlice.actions.setList(res.importers));
      dispatch(importersSlice.actions.setIsLoading(false));
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
      dispatch(importersSlice.actions.setIsLoading(false));
    }
  };

export const fetchImporterDetails =
  (id: string): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      const res = await request({
        path: `importer/retrieve-importer-details/${id}`,
        method: 'GET',
        authenticate: true,
      });
      dispatch(importersSlice.actions.setImporterDetails(res.importer));
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const generateDocumentAction =
  (importerId: string, type: POADocumentType): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      await request({
        path: `importer/generate-document/${importerId}/${type}`,
        method: 'POST',
        authenticate: true,
      });

      dispatch(fetchImporterDetails(importerId));
      toast.success(`${type} document generated!`);
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;

      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const deleteDocumentAction =
  (importerId: string, fileName: string, type: POADocumentType): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    console.log(fileName);
    try {
      await request({
        path: `importer/delete-document/${importerId}/${fileName}`,
        method: 'POST',
        authenticate: true,
      });
      dispatch(fetchImporterDetails(importerId));
      toast.success(`${type} document deleted!`);
    } catch (error) {
      console.error(error);
      toast.error((error as AxiosError).response?.data.message || 'Something went wrong');
    }
  };

export const downloadDocumentAction =
  (importerId: string, fileName: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    try {
      const { data, headers } = await request(
        {
          path: `importer/download-document/${importerId}/${fileName}`,
          method: 'GET',
          authenticate: true,
          fileDownload: true,
        },
        true,
      );

      const href = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = href;
      const filename = headers['content-disposition']?.match(/filename="([^"]*)"/)[1] || `${fileName}`;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(fetchImporterDetails(importerId));
    } catch (error) {
      console.error(error);
      toast.error((error as AxiosError).response?.data.message || 'Something went wrong');
    }
  };

export const sendDocumentAction =
  (importerId: string, type: POADocumentType): AppThunk<Promise<void>> =>
  async (dispatch: any) => {
    try {
      await request({
        path: `importer/send-${type === POADocumentType.POA ? 'poa' : 'vat'}/${importerId}`,
        method: 'POST',
        authenticate: true,
      });

      dispatch(fetchImporterDetails(importerId));
      toast.success(`${type} document sent!`);
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;

      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };
